
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300..700&display=swap');

* {
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  &::before,
  &::after {
    box-sizing: inherit;
  }

  &::-webkit-scrollbar {
    -webkit-appearance: none;
  }

  &::-webkit-scrollbar:vertical {
    width: 11px;
  }

  &::-webkit-scrollbar:horizontal {
    height: 11px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 2px solid #f4f0fa;
    background-color: rgba(0, 0, 0, 0.2);
  }
}

html {
  height: 100%;
  min-height: 100%;
  font-size: 10px;
  font-family: "Open Sans", sans-serif;
}

body {
  height: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1 1 0%;
  background: #ffffff;
}

#root {
  display: flex;
  flex-direction: column;
  flex: 1 1 0%;
  min-height: 100%;
  position: relative;
  max-height: 100%;
}
